@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://use.typekit.net/cmy7azy.css";

@layer base {
  html {
    @apply font-serif;
    background-color:#F6ECD7;
  }
}

:root {
  background-color: #44403c;
}


#logo-container {
  /* apply keyframes animation */
  animation: logoContainer 2s linear infinite;
  animation-delay: calc(var(--scroll) * -1s);
  animation-play-state: paused;
  
}

.scale-logo {
  animation: scaleLogo 0.5s ease-in-out 1 forwards;
}

@keyframes scaleLogo {
  0% {
    height: 10rem;
  }

  50%, 100% {
    height: 5rem;
  }
} 


@keyframes logoContainer {
  0% {
  }

  50%, 100% {
  }

}

h1 {
  font-family: ofelia-display, sans-serif;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  text-transform: uppercase;
  margin-bottom:1rem;
}

h2 {
  font-family: adobe-garamond-pro, sans-serif;
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-style: italic;
  margin-bottom:1rem;

}

h3 {
  font-weight: bold;
  margin-bottom:1rem;
}

ul {
  list-style-type: disc;
  margin-left:1.3rem;
}

ol {
  list-style-type: decimal;
  margin-left:1.3rem;
}

a.text-link:link, a.text-link:active, a.text-link:visited {
  text-decoration: underline;
  text-decoration-color: #9a9898;
}

a.text-link:hover {
  text-decoration: none;
}