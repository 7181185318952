:root {
  background-color: white;
  color-scheme: only light;
  * {
    color-scheme: only light;
  }
}

.ql-snow .ql-editor p{
  font-size:1.17em 
}

h1, .ql-snow .ql-editor h1 {
  font-family: ofelia-display, sans-serif;
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  text-transform: uppercase;
  margin-bottom:1rem;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]{
  font-family: ofelia-display, sans-serif;
  text-transform: uppercase;
}




h2, .ql-snow .ql-editor h2 {
  font-family: adobe-garamond-pro, sans-serif;
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  font-style: italic;
  margin-bottom:1rem;

}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]{
  font-family: adobe-garamond-pro, sans-serif;
  font-style: italic;
}

h3, .ql-snow .ql-editor h3 {
  font-weight: bold;
  margin-bottom:1rem;
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]{
  font-weight: bold;
}